<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand @click="toggleBuilderSidebar" v-if="!isSidebarOpen">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="13 17 18 12 13 7" />
        <polyline points="6 17 11 12 6 7" />
      </svg>
    </b-navbar-brand>
    <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">right</b-navbar-nav>
        </b-collapse> -->
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["isSidebarOpen"])
  },
  methods: {
    ...mapActions(["toggleBuilderSidebar"])
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  z-index: 0;
  height: 56px;
  width: 100%;
  .navbar-brand {
    cursor: pointer;
  }
}
</style>

