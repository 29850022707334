<template>
  <div class="elements-wrapper">
    <draggable
      :sort="false"
      class="row m-auto" 
      v-model="getWidgets.common"
      :group="{ name: 'group-elements', pull: 'clone', put: false }"
      style="padding-right: 24px;padding-top: 20px;"
    >
      <div
        class="col-5" style="margin-right: -62px;"
        v-for="(widget, index) in getWidgets.common"
        :key="index"
      >
        <div class="card  widgets" style="height: 83px; margin-right: 43px;">
          <img :src="require(`@/assets/builder_elementor/icons/${widget.icon}`)" style="width: 36px; margin: 10px 10px 10px 10px; align-self: center;" />
          <small class="text-capitalize text-secondary" style="text-align: center; ">{{
            widget.name
          }}</small>
        </div>
      </div>
    </draggable>
    
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
export default {
  components: {
    draggable
  },
  computed: {
    ...mapGetters(["getWidgets"])
  }
};
</script>

<style lang="scss" scoped>
.elements-wrapper {
  max-height: 98%;
  margin-bottom: 200px;
  .row {
    .widgets {
      cursor: grab;
      &:hover {
        -webkit-box-shadow: -1px 9px 14px -8px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: -1px 9px 14px -8px rgba(0, 0, 0, 0.75);
        box-shadow: -1px 9px 14px -8px rgba(0, 0, 0, 0.75);
        transition: 0.3s;
      }
    }
    .not-available {
      .widgets {
        cursor: not-allowed;
        &:hover {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
      }
    }
  }
}
</style>

