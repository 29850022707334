import { render, staticRenderFns } from "./ListWidget.vue?vue&type=template&id=6d6cdf5c&scoped=true"
import script from "./ListWidget.vue?vue&type=script&lang=js"
export * from "./ListWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6cdf5c",
  null
  
)

export default component.exports