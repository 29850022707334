<template>

  <div class="columns-wrapper ">
        <span class="d-md-flex" v-for="preview in previewFooter" :key="preview.id">
            <div v-for="column in preview.columns" :key="column.id" style="inline-size: -webkit-fill-available;">
                
                <div class="p-0" :style="{width: column.style.fill ? '100%' : 'auto', height: '100%'}">
                    <div class="column-item h-100 d-flex flex-column justify-content-center">
                        <div class="item-content h-100" :style="{background: column.style.background, padding:column.style.padding+'px'}">
                            <div class="element-wrapper h-100">
                                <div class="h-100 d-flex justify-content-center align-items-start">
                                    <span class="w-100" >
                                        <div class="w-100" v-for="element in column.elements" :key="element.id">
                                            
                                            <div class="element-item-wrapper">
                                                <div class="widget-wrapper" >
                                                    <div :class="element.name == 'Heading' ? 'Heading-wrapper h-100': element.name == 'Text Link' ? 'text-widget-wrapper':element.name == 'List' ? 'list-widget-wrapper d-flex justify-content-center':element.name == 'Text Editor' ? 'text-widget-wrapper':element.name == 'Button' ? 'button-widget-wrapper d-flex justify-content-center':element.name == 'Image' ? 'text-widget-wrapper d-flex justify-content-center':element.name == 'Spacer' ? '':'' ">
                                                        <h1 v-if="element.name == 'Heading'" :style="{ 'font-size':element.content.style.fontSize+'px' , 'font-weight': element.content.style.fontWeight, 'text-align': element.content.style.textAlign, 'border-radius': element.content.style.borderRadius+'px', 'color': element.content.style.color, 'background': element.content.style.background, 'margin': element.content.style.margin+'px', 'padding': element.content.style.padding+'px', 'visibility': 'visible', 'height': 'auto'}">
                                                            {{element.content.text}}
                                                        </h1>
                                                        <a v-if="element.name == 'Text Link'" :href="element.content.link" :style="{ 'font-size':element.content.style.fontSize+'px' , 'font-weight': element.content.style.fontWeight, 'text-align': element.content.style.textAlign, 'border-radius': element.content.style.borderRadius+'px', 'color': element.content.style.color, 'background': element.content.style.background, 'margin': element.content.style.margin+'px', 'padding': element.content.style.padding+'px', 'visibility': 'visible', 'height': 'auto'}">
                                                            {{element.content.text}}
                                                        </a>

                                                        <ul v-if="element.name == 'List'" :style="{ 'font-size': element.content.style.fontSize+'px', 'font-weight': element.content.style.fontWeight, 'border-radius': element.content.style.borderRadius+'px', 'padding': element.content.style.padding == '0' ? '':element.content.style.padding+'px', 'margin': element.content.style.margin+'px', 'color': element.content.style.color, 'background': element.content.style.background }">
                                                            <li v-for="list in element.content.list" :key="list.id" :style="{ 'text-align': element.content.style.textAlign}">
                                                                <div style="visibility: visible;">
                                                                    {{list.text}}
                                                                </div>
                                                            </li>
                                                        </ul>

                                                        <p v-if="element.name == 'Text Editor'" :style="{'font-size': element.content.style.fontSize+'px', 'font-weight': element.content.style.fontWeight, 'text-align': element.content.style.textAlign, 'border-radius': element.content.style.borderRadius+'px', 'color': element.content.style.color, 'background': element.content.style.background, 'margin': element.content.style.margin+'px', 'padding': element.content.style.padding+'px', 'visibility': 'visible', 'height': 'auto'}">
                                                            {{element.content.text}}
                                                        </p>
                                                        <button v-if="element.name == 'Button'" :style="{'font-size': element.content.style.fontSize+'px', 'font-weight': element.content.style.fontWeight, 'border-radius': element.content.style.borderRadius+'px', 'color': element.content.style.color, 'background': element.content.style.background, 'padding': element.content.style.padding+'px', 'margin': element.content.style.margin+'px'}">
                                                            {{element.content.text}}
                                                        </button>

                                                        <img fluid v-if="element.name == 'Image'" :src="element.content.src" alt="" :width="element.content.style.width" :style="{ 'border-radius': element.content.style.borderRadius+'px', 'padding': element.content.style.padding+'px', 'margin': element.content.style.margin+'px'}">

                                                        <div v-if="element.name == 'Spacer'" :style="{'background': element.content.style.background, 'height': element.content.style.height}">

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </span>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
      
    </div>
</template>


<script>
import {BCol, BRow} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow
  },
  props: {
    previewFooter: []
  },
};
</script>

<style lang="scss" scoped>
.preview-wrapper {
  padding: 0px 0px;
  background: #fff;
}
</style>
