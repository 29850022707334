<template>
  <div>
    <b-img :src="this.src" fluid alt="Responsive image"></b-img>
    <b-form-file
      v-model="file"
      @change="onFileChange"
      ref="file-input"
      class="my-2"
    ></b-form-file>
    <!-- <b-button @click="clearFiles" variant="info">Reset</b-button> -->
    <!-- <div class="d-flex align-items-center">
      <p class="mt-2 w-100 text-left">
        Selected file: <b>{{ file ? file.name : "" }}</b>
      </p>
      <button class="btn btn-success" @click="upload">upload</button>
    </div> -->
  </div>
</template>

<script>
import axios from '@axios'
export default {
  props: {
    imageSrc: String
  },
  data() {
    return {
      file: null,
      src: ""
    };
  },
  methods: {
    async onFileChange(event) {
      const formData = new FormData()
      for (const e of event.target.files) { 
          formData.append('images', e, e.name);
        }
      formData.append('path', 'footer')
      const response = await axios.post(`setImages/${this.$store.state.whitelabelCurrencyNabvar.whitelabel.client}`, formData)
      this.src = response.data.files[0];
      this.upload()
    },
    upload() {
      this.$emit("fileUploaded", this.src);
      this.$swal.fire({
        icon: "success",
        title: "Upload Success!",
        text: "Image uploaded successfuly"
        // footer: "<a href>Why do I have this issue?</a>"
      });
      this.$refs["file-input"].reset();
    },
  },
  created() {
    this.src = this.imageSrc;
  }
};
</script>

<style></style>

