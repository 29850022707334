<template>
  <div class="builder-main-wrapper container ">
    <SectionList />
  </div>
</template>

<script>
import SectionList from "@/builder_components/section/SectionList.vue";
export default {
  components: {
    SectionList
  }
};
</script>

<style lang="scss" scoped>
.builder-main-wrapper {
  padding: 0px 0px;
  background: #fff;
}
</style>

