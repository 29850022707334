<template>
  <div class="column-settings-wrapper">
    <WidthType
      :width="column.style.fill"
      notice="Dynamic Layout Only"
      :disable="column.grid ? true : false"
      @changeWidth="e => (column.style.fill = e)"
    />
    <hr />
    <VerticalContent
      :vertical="column.style.y"
      @changeVertical="e => (column.style.y = e)"
    />
    <RangeNumber
      :value="parseInt(column.style.padding)"
      label="Padding"
      @valueChange="e => (column.style.padding = e)"
      min="0"
      max="200"
      step="0"
      v-if="column.style.padding"
    />
    <hr />
    <ColorPicker
      :colorFor="column.style.background"
      label="Background"
      @colorChange="backgroundColor"
      v-if="column.style.background"
    />
  </div>
</template>

<script>
import WidthType from "@/builder_components/utility/WidthType.vue";
import RangeNumber from "@/builder_components/utility/RangeNumber.vue";
import ColorPicker from "@/builder_components/utility/ColorPicker.vue";
VerticalContent;
import VerticalContent from "@/builder_components/utility/VerticalContent.vue";
export default {
  components: {
    WidthType,
    RangeNumber,
    VerticalContent,
    ColorPicker
  },
  props: {
    column: Object
  },
  methods: {
    backgroundColor(e) {
      this.column.style.background = e ? e : this.column.style.background;
    }
  }
};
</script>

<style lang="scss" scoped></style>

