<template>
  <div class="form-group">
    <label for="link" class="ml-1 w-100 text-left font-weight-bold">{{
      label
    }}</label>
    <input type="text" 
      class="form-control"
      name="link"
      id="link"
      v-model="myTextLink"
    >
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    label: String
  },
  computed: {
    myTextLink: {
      get() {
        return this.link;
      },
      set(val) {
        this.$emit("textChange", val);
      }
    }
  }
};
</script>

<style></style>

